// Typography
.h1 {
    @apply text-4xl font-extrabold tracking-tighter;
}

.h2 {
    @apply text-3xl font-extrabold tracking-tighter;
}

.h3 {
    @apply text-3xl font-extrabold;
}

.h4 {
    @apply text-2xl font-extrabold tracking-tight;
}

@screen md {
    .h1 {
        @apply text-5xl;
    }

    .h2 {
        @apply text-4xl;
    }
}

// Buttons
.btn,
.btn-sm {
    @apply font-medium inline-flex items-center justify-center border border-transparent rounded leading-snug transition duration-150 ease-in-out;
}

.btn {
    @apply px-8 py-3;
}

.btn-sm {
    @apply px-4 py-2;
}

// Forms
.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox,
.form-radio {
    @apply bg-white border border-gray-300 focus:border-gray-400;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox {
    @apply rounded;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select {
    @apply leading-snug py-3 px-4;
}

.form-input,
.form-textarea {
    @apply placeholder-gray-500;
}

.form-select {
    @apply pr-10;
}

.form-checkbox,
.form-radio {
    @apply text-teal-600;
}

.dark {

    .h4 {
        @apply font-bold;
    }

    .form-input,
    .form-textarea,
    .form-multiselect,
    .form-select,
    .form-checkbox,
    .form-radio {
        @apply bg-gray-800 border border-gray-600 focus:border-gray-500;
    }

    .form-input,
    .form-textarea {
        @apply placeholder-gray-400;
    }
}